<template>
  <div id="login">
    <b-container>
      <b-row class="min-vh-100 justify-content-center align-items-center">
        <b-col cols="12" md="6" xl="4">
          <b-card class="padding-top-26 padding-bottom-16">
            <b-row>
              <b-col cols="12">
                <b-img :src="`${publicPath}img/brand/hol_logo.png`" class="mx-auto d-block" style="min-width:200px; max-width: 50%;" alt="House Of Living logo" />
              </b-col>
              <b-col cols="12" class="text-center text-muted margin-top-26">
                <p>{{ trans('auth.login.login_admin') }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form class="margin-top-16" @submit.prevent>
                  <b-form-group id="inputemailgroup" :label="trans('auth.login.email')" label-for="login_email">
                    <b-form-input
                      id="login_email"
                      v-model="$v.loginForm.email.$model"
                      name="login_email"
                      class="form-control-lg"
                      :state="formErrors.email"
                      type="email"
                      :placeholder="trans('auth.login.email_placeholder')"
                      aria-describedby="email-msg"
                      trim
                      @keyup.13="login"
                    ></b-form-input>
                    <b-form-invalid-feedback id="email-msg">
                      <span v-if="!$v.loginForm.email.required">{{ trans('auth.login.msg_email_required') }}</span>
                      <span v-else-if="!$v.loginForm.email.email">{{ trans('auth.login.msg_email_email') }}</span>
                      <span v-if="!$v.loginForm.email.FBerrors">{{ loginForm.fbError }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group id="inputpasswordgroup" :label="trans('auth.login.password')" label-for="login_password">
                    <b-form-input
                      id="login_password"
                      v-model="$v.loginForm.password.$model"
                      name="login_password"
                      class="form-control-lg"
                      :state="formErrors.password"
                      type="password"
                      :placeholder="trans('auth.login.password_placeholder')"
                      aria-describedby="password-msg"
                      trim
                      @keyup.13="login"
                    ></b-form-input>
                    <b-form-invalid-feedback id="password-msg">
                      <span v-if="!$v.loginForm.password.required">{{ trans('auth.login.msg_password_required') }}</span>
                      <span v-if="!$v.loginForm.password.minLength">{{ trans('auth.login.msg_password_minlength') }}</span>
                      <span v-if="!$v.loginForm.password.FBerrors">{{ loginForm.fbError }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <div class="extras">
                    <b-form-row>
                      <b-col>
                        <b-form-checkbox
                          id="rememberme"
                          v-model="loginForm.rememberme"
                          name="rememberme_checkbox"
                          value="false"
                          unchecked-value="true"
                        >
                          {{ trans('auth.login.remember_me') }}
                        </b-form-checkbox>
                      </b-col>
                      <b-col class="text-right">
                        <b-link href="#">
                          {{ trans('auth.login.forgot_password') }}
                        </b-link>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="fbAccessError !== ''">
                      <!-- eslint-disable-next-line  -->
                      <b-alert
                        show
                        variant="warning"
                        class="mt-4 w-100"
                      >
                        <!-- eslint-disable-next-line-->
                        <div v-html="fbAccessError"></div>
                      </b-alert>
                    </b-form-row>
                  </div>
                  <b-button variant="primary" size="lg" class="w-100 margin-top-26" @click="login">
                    <span v-if="!loading">{{ trans('auth.login.btn_cta') }}</span>
                    <b-spinner v-else variant="white"></b-spinner>
                  </b-button>
                </b-form>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { email, required } from 'vuelidate/lib/validators'

  const fb = require('../firebaseConfig')

  export default {
    name: 'Login',
    mixins: [validationMixin],
    data () {
      return {
        fbAccessError: '',
        loading: false,
        publicPath: process.env.BASE_URL,
        loginForm: {
          email: '',
          password: '',
          rememberme: false,
          submitted: false,
          fbError: ''
        }
      }
    },
    validations () {
      return {
        loginForm: {
          email: {
            required,
            email,
            /**
             * @return {boolean}
             */
            FBerrors () {
              return (
                this.loginForm.fbError === ''
              )
            }
          },
          password: {
            required,
            minLength: 5,
            /**
             * @return {boolean}
             */
            FBerrors () {
              return (
                this.loginForm.fbError === ''
              )
            }
          }
        }
      }
    },
    computed: {
      formErrors () {
        return {
          email: (this.$v.loginForm.email.$dirty && this.loginForm.submitted) ? !this.$v.loginForm.email.$error : null,
          password: (this.$v.loginForm.password.$dirty && this.loginForm.submitted) ? !this.$v.loginForm.password.$error : null
        }
      }
    },
    methods: {
      async login () {
        this.loading = true
        this.loginForm.submitted = true
        this.loginForm.fbError = ''

        // Error-handling clientside
        this.$v.loginForm.$touch()

        if (this.$v.loginForm.$anyError) {
          this.loading = false
          return
        }

        const loginSuccess = await fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then((user) => {
          // this.$store.commit('auth/setCurrentUser', user.user) // Enable this when we have a seperate user-table
          return true
        }).catch((err) => {
          this.loading = false
          const FBerror = {
            'auth/user-not-found': this.trans('auth.login.msg_email_password_notfound'),
            'auth/wrong-password': this.trans('auth.login.msg_email_password_wrongpassword'),
            'auth/too-many-requests': this.trans('auth.login.msg_email_passsword_too_many_requests')
          }
          this.loginForm.fbError = FBerror[err.code]
          return false
        })

        if (loginSuccess) {
          const claims = await fb.auth.currentUser.getIdTokenResult()
            .then(res => res.claims)
            // eslint-disable-next-line prefer-promise-reject-errors
            .catch(err => err)

          if (claims.admin !== undefined && claims.admin) {
            await this.$store.dispatch('auth/fetchUserProfile')
            await this.$router.push('dashboard')
          } else {
            this.loginForm.fbError = 'En feil oppstod'
            this.fbAccessError = '<i class="fal fa-exclamation-triangle mr-2"></i><span class="mb-0">Du må logge deg inn på vår <a style="font-weight: bold;" href="https://vary-frontend.web.app/login">frontend-løsning</a></span>'
            await fb.auth.signOut()
          }

          this.loading = false
        }
      }
    }
  }
</script>
